import Hls from 'hls.js'

export default {
    name: 'webcam',
    data() {
        return {
            menu: false,
            mode: 'livestream',
            url: window.webcamConfig.url,
            images: window.webcamConfig.images,
            cam: window.webcamConfig.images[0].name,
            resolution: "P_960",
            time: new Date().getTime()
        }
    },
    methods: {
        toggleFullscreen: function() {toggleFullscreen(document.getElementById("webcam"))},
        showMenu: function() {showMenu()},
        startInterval: function () {
          const self = this
          setInterval(function() {
              self.time = new Date().getTime()
              self.refreshImage();
          }, 60000);
        },
        refreshImage: function() {
          var image = document.getElementById('image');
          this.resolution="P_3840";
          if(document.body.clientWidth)
          {
              if(document.body.clientWidth<=960) this.resolution="P_1920";
              if(document.body.clientWidth<=320) this.resolution="P_960";
          }

          const src = this.url+this.resolution+"-"+this.cam+".jpg";

          loadImage(src, image);
        }
    },
    mounted() {
        console.log("app mounted");
        if(location.hash!="") this.mode = location.hash.replace('#', '')
        loadHLS();
        showMenu(3000);
        this.startInterval();
        this.refreshImage();

        document.getElementById('el').addEventListener("ontouchstart", function( event ) {
          showMenu();
        }, false);
    }
  }

var hls;
function loadHLS()
{
    var video = document.getElementById('livestream');

    if(Hls.isSupported()) {
        var config = {
            debug: false,
            enableWorker: true,
            maxBufferHole: 120,
            fragLoadingMaxRetry: 2,
            liveSyncDurationCount: 6,
            startLevel: 1
        };
        hls = new Hls(config);

        hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch(data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                console.log("fatal network error encountered, try to recover");
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.log("fatal media error encountered, try to recover");
                hls.recoverMediaError();
                break;
              default:
                // cannot recover
                hls.destroy();
                console.log("fatal media error encountered, destroy an reload");
                loadHLS();
                break;
              }
          }
          });

        hls.loadSource(window.webcamConfig.url + 'livestream.m3u8');
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
            video.play();
        });
    }
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = window.webcamConfig.url + 'fmp4/livestream/index_1080p.m3u8';
        video.addEventListener('loadedmetadata',function() {
            video.play();
        });
    }
}

/*setInterval(function(){ 
  var video = document.getElementById('livestream');
  console.log(video.paused);
  if(video.paused)
  {
    hls.destroy();
    console.log("fatal media error encountered, destroy an reload");
    loadHLS();
  }
 }, 30000);*/

 function handleVisibilityChange() {
  if (document.hidden) {
    console.log("hidden");
    hls.destroy();
  } else  {
    console.log("shown");
    loadHLS();
  }
}

document.addEventListener("visibilitychange", handleVisibilityChange, false);


var player_controls_timer;
function showMenu(timeout = 3000) {
    document.getElementById('player_controls').style.opacity = 1.0;
    clearTimeout(player_controls_timer);
    player_controls_timer = setTimeout(function() {
        document.getElementById('player_controls').style.opacity = 0.0;
    }, timeout);
}

function toggleFullscreen(elem) {
    elem = elem || document.documentElement;
    if (!document.fullscreenElement && !document.mozFullScreenElement &&
      !document.webkitFullscreenElement && !document.msFullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
      if(elem.requestFullscreen || elem.msRequestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen)
      {
          document.getElementById("webcam").classList.add('fullscreen');
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      document.getElementById("webcam").classList.remove('fullscreen');
    }

    if(screen.orientation.lock) screen.orientation.lock("landscape");
  }

  var livebild_xhr;
  function loadImage(url, element) {
    if(livebild_xhr) livebild_xhr.abort();
    livebild_xhr = new XMLHttpRequest();

    livebild_xhr.open( "GET", url, true );
    livebild_xhr.responseType = "arraybuffer";

    livebild_xhr.onload = function( e ) {
        if (livebild_xhr.readyState === 4) {
                if (livebild_xhr.status === 200) {
                        element.src = url;
            }
        }
    };

    livebild_xhr.send();
}